@use "../../foundation/variable" as var;

.c-bg-blue {
    background-color: var.$color-bg-primary;
}

.c-bg-white {
    background-color: var.$color-bg-secondary;
}

.c-bg-white--round {
    border-radius: 10px;
}

.c-bg-grey {
    background-color: var.$color-bg-tertiary;
}