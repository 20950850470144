@use "./variable" as var;

// breakpoint
@mixin responsive($breakpoint) {
    @if map-has-key(var.$breakpoints, $breakpoint) {
        @media screen and #{inspect(map-get(var.$breakpoints, $breakpoint))} {
        @content;
        }
    }
}

// z-index
@mixin z-index($context) {
    z-index: map-get(var.$z-index, $context);
}