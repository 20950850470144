@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-btn {

    & p {
        line-height: unset;
    }

    &:hover .p-btn__icon--primary {
        fill: var.$color-bg-btn-icon-primary-hover;
    }

    &:hover .p-btn__icon--secondary {
        fill: var.$color-bg-btn-icon-secondary-hover;
    }

    &__icon,
    &__icon--primary,
    &__icon--secondary {
        display: inline-block;
        width: 1.4rem;
        height: 1.5rem;
        margin-right: 0.5em;
        vertical-align: middle;
        fill: var.$color-bg-btn-icon;
    }
}