// margin
.u-m-auto {
	margin: auto;
}

.u-mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.u-my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.u-mt-auto {
	margin-top: auto;
}

.u-mr-auto {
	margin-right: auto;
}

.u-ml-auto {
	margin-left: auto;
}

.u-mb-auto {
	margin-bottom: auto;
}
