@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-point {
    padding-top: 4.2rem;

    @include mix.responsive(md) {
        gap: 3.3rem;
        padding-top: 2.6rem;
    }

    &__title {
        @include mix.responsive(md) {
            font-size: var.$font-sp-point-title;
        }
    }

    &__list {
        gap: 5.1rem;
        margin-top: 4.2rem;

        @include mix.responsive(md) {
            gap: 3.3rem;
            margin-top: 2.6rem;
        }
    }

    &__thumbnail {
        width: 160px;
    }

    &__description {
        margin-top: 2.4rem;
        font-weight: 700;
    }
}