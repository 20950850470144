@use "../foundation/variable" as var;
@use "../foundation/mixin" as mix;

.l-header {
	width: 100%;
	@include mix.z-index(menu);

	&--fixed {
		position: fixed;
		top: 0;
		left: 0;
	}

	&__container {
		position: relative;
		@include mix.z-index(nav);
	}

	&__top {
		padding: 0.75em 0;
	}

	&__logo {
		color: var.$color-text-logo;

		&--top {
			@include mix.responsive(md) {
				font-size: var.$font-logo-top-sp;
			}
		}

		&--left {
			@include mix.responsive(md) {
				font-size: var.$font-logo-left-sp;
			}
		}
	}

	&__btn-area {
		gap: 1.2rem;
	}

	&__bottom {
		padding: 1.8rem 0 2.5rem;
	}

	&__list {
		gap: 2.4rem;
		@include mix.responsive(md) {
			gap: 1.2rem;
		}
	}
}
