@use "../../foundation/variable" as var;

.c-link{
    font-weight: 700;
    color: var.$color-text-link;
    transition: 0.3s;

    &:hover {
        color: var.$color-text-link-hover;
    }
}