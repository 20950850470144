@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.slick-prev,
.slick-next {
    width: 48px;
	height: 48px;

    &::before {
        font-size: 48px;
        line-height: 0.5;
    }
}

.slick-prev {
    left: -24px;

    &::before {
        background: url(../images/lp/icon_carousel-arrow_prev.svg)!important;
        background-size: contain;
    }
}

.slick-next {
    right: -24px;

    &::before {
        background: url(../images/lp/icon_carousel-arrow_next.svg)!important;
        background-size: contain;
    }
}

.slick-slide{
    height: auto;
}


.slick-arrow{
    @include mix.z-index(btn);

    &::before{
        content:""!important;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: unset;
    }
}

.slick-active {
    color: var.$color-bg-carousel-dot-active;
}

.slick-dots {
    & li {
        width: 4px;
    }

    & li button:before {
        color: var.$color-bg-carousel-dot-inactive;
        font-size: 8px;
        opacity: unset;
    }

    & li.slick-active button:before {
        color: var.$color-bg-carousel-dot-active;
        opacity: unset;
    }
}