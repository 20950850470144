@use "../foundation/variable" as var;
@use "../foundation/mixin" as mix;

.l-footer {
	&__top {
        padding: 2.8rem 0  1.4rem;
        color: var.$color-text-nav-white;

        @include mix.responsive(md) {
            padding: 3.5rem 0  2.8rem;
        }
	}

    &__list {
        display: flex;
        gap: 2.4rem;

        @include mix.responsive(md) {
            gap: 1.2rem;
        }

        @include mix.responsive(sm) {
            flex-direction: column;
            gap: 2.4rem;
        }

        & a {
            @include mix.responsive(sm) {
                text-decoration: underline;
            }
        }
    }

    &__logo {
        margin-top: 5.8rem;
        color: var.$color-text-logo-white;

        @include mix.responsive(md) {
            margin-top: 3.1rem;
        }

        &--bottom {
            gap: 0.7rem;
        }

        &--bottom {
            margin-top: 1.2rem;
        }
    }

	&__bottom {
		padding: 6rem 0 2.2rem;

        @include mix.responsive(md) {
            padding: 3.5rem 0 1.2rem;
        }
	}

    &__company-logo {
        max-width: 130px;
    }

    &__copyright {
        display: inline-block;
        margin-top: 1.2rem;
    }
}
