@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-privacy {
    @include mix.responsive(md) {
        padding: 3.8rem 1.5rem 2.9rem;
    }

    &__content {
        gap: 2.3rem;

        @include mix.responsive(md) {
            gap: 3rem;
        }
    }

    &__thumbnail {
        width: 150px;
    }

    &__body {
        max-width: 57rem;

        @include mix.responsive(md) {
            flex-wrap: nowrap;
        }

        @include mix.responsive(md) {
            flex-wrap: wrap;
        }
    }

    &__title {
        font-size: var.$font-privacy-title;
        font-weight: 700;
    }

    &__block {
        margin-top: 3.2rem;
    }

    &__description {
        font-size: var.$font-privacy-description-pc;

        @include mix.responsive(md) {
            font-size: var.$font-privacy-description-sp;
        }

        &--bottom {
            margin-top: 2.8rem;
        }
    }
}