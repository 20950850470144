@use "../../foundation/variable" as var;

.c-border {
    border-bottom: 1px solid var.$color-border-nav;
    padding-bottom: 0.8rem;
}

.c-border-nav-white {
    border-bottom: 1px solid var.$color-border-nav-white;
    padding-bottom: 0.8rem;
}

.c-border-menu {
    width: 100%;
    border-bottom: 1px solid var.$color-border-menu-list;
	padding-bottom: 2.4rem;
}


.c-border-dots {
    padding-bottom: 0.8rem;
    width: 100%;
    border-bottom: 3px dotted var.$color-border-top-contents;
}

// flow
.c-flow-border,
.c-flow-border-sp {
    width: 100%;
    fill: var.$color-bg-flow;
}

.c-flow-border {
    max-width: 66.8rem;
    height: 13.5rem;
}

.c-flow-border-sp {
    max-width: 7.6rem;
    height: 24.6rem;
}