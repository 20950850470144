@use "../foundation/mixin" as mix;

//pc
.u-hidden-pc {
	display: none;
	@include mix.responsive(md) {
		display: block;
	}
}

//tablet
.u-hidden-tab {
	@include mix.responsive(md) {
		display: none;
	}
	@include mix.responsive(sm) {
		display: block;
	}
}

//sp
.u-hidden-sp {
	@include mix.responsive(sm) {
		display: none;
	}
}
