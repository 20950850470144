@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-guide {
    &__top {
        color: var.$color-text-base-white;
    }

    &__title {
        margin-top: 1.6rem;

        @include mix.responsive(md) {
            margin-top: 2.1rem;
        }
    }

    &__movie {
        margin-top: 4.2rem;

        & iframe {
            max-width: 58.8rem;
            width: 588px;
            height: 311px;

            @include mix.responsive(sm) {
                width: 100%;
                aspect-ratio: 2.414 / 1;
            }
        }
    }

    &__lead {
        margin-top: 1.6rem;
        font-weight: 700;

        @include mix.responsive(md) {
            margin-top: 2.2rem;
            font-size: var.$font-sp-guide-lead;
        }
    }

    &__bottom {
        padding: 8rem 0 6.4rem;

        @include mix.responsive(md) {
            padding: 6.4rem 0 3.5rem;
        }
    }

    &__list {
        gap: 6.5rem 0;

        @include mix.responsive(md) {
            flex-direction: column;
            gap: 5rem 0;
        }
    }

    &__item {
        gap: 0 2.4rem;
        max-width: 84rem;

        @include mix.responsive(md) {
            gap: 1.6rem 0;
        }

        @include mix.responsive(sm) {
            max-width: 35.5rem;
        }
    }

    &__block {
        position: relative;
    }

    &__tag {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -37px;
        left: -44px;

        @include mix.responsive(md) {
            left: 50%;
            transform: translatex(-50%);
            width: 18.6rem;
        }
    }

    &__sub-title {
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);

        &--one,
        &--two,
        &--three {
            left: 45%;
        }
    }

    &__thumbnail {
        @include mix.responsive(md) {
            width: 100%;
        }
    }

    &__description {
        width: 40%;

        @include mix.responsive(md) {
            font-size: var.$font-sp-guide-description;
            width: 55%;
        }

        @include mix.responsive(sm) {
            width: 90%;
        }
    }
}

