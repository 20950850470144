@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.c-card {
	&__title {
		font-size: 1.8rem;
		font-weight: 700;
	}

	&__description {
		font-size: 1.6rem;
	}

	&__label {
		font-size: 1rem;
	}
}

.c-card__body--horizon {
	width: calc(100% - 150px);
	padding: 0.4em;
}

.c-card--shadow {
	box-shadow: 2px 3.5px 9.2px 0.8px rgba(145, 145, 145, 0.4);
}