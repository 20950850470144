@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.c-logo {
	font-size: var.$font-logo;
	font-weight: 700;

	&--strong {
		font-size: var.$font-logo-strong;
	}
}