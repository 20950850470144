@use "./variable" as var;
@use "./mixin" as mix;

html {
    scroll-padding-top: 140px;
    scroll-behavior: smooth;
    font-size: 62.5%;

    @include mix.responsive(md) {
        scroll-padding-top: 130px;
    }

    @include mix.responsive(sm) {
        scroll-padding-top: 70px;
    }
}

body {
    padding-top: 140px;
    background-color: var.$color-bg-secondary;
    color: var.$color-text-base;
    font-family: "hiragino sans","Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "meiryo", "MS Pゴシック", "MS PGothic", sans-serif;
    font-size: var.$font-pc-text;
    line-height: 1.2;

    @include mix.responsive(md) {
        padding-top: 130px;
        font-size: var.$font-sp-text;
    }

    @include mix.responsive(sm) {
        padding-top: 70px;
        font-size: var.$font-sp-text;
    }
}

main {
    overflow: hidden;
}

dd {
    line-height: 1.5;
}

p {
    line-height: 2;
}

img {
    width: 100%;
    height: auto;
}