@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-contents {
    padding-top: 3.4rem;

    @include mix.responsive(md) {
        padding-top: 2.9rem;
        padding-bottom: 3.6rem;
    }

    &__title {
        margin-top: 1.6rem;
    }

    &__list {
        gap: 2.4rem;
        margin-top: 4.2rem;
        max-width: 72.4rem;
    }


    &__card {
        padding: 2rem;
        max-width: 350px;
        border-radius: 8px;
    }

    &__sub-title{
        padding: 6.4rem 0;
        color: var.$color-title-contents;

        &--left {
            background-image: url(../images/lp/contents_business-manner.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &--right {
            background-image: url(../images/lp/contents_privacy-protection.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &__description {
        margin-top: 1.2rem;
        font-size: var.$font-contents-description;
        line-height: 2;
    }

    &__label {
        margin-top: 3.55rem;
    }
}