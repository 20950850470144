@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.c-btn {
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: var.$color-text-btn;
    font-size: var.$font-pc-btn;
    font-weight: 700;
    padding: 1.6rem 4.2rem;
    transition: 0.3s;
    line-height: unset;

    @include mix.responsive(md) {
        padding: 1.6rem 3.2rem;
        font-size: var.$font-sp-btn;
    }

    &--shadow {
        box-shadow: 2px 3.5px 9.6px 0.4px rgba(16, 60, 142, 0.56);
    }

    &--primary {
        background-color: var.$color-bg-btn-primary;
        border: 1px solid var.$color-border-btn-primary;

        &:hover {
            background-color: var.$color-bg-btn-primary-hover;
            color: var.$color-text-btn-primary-hover;
        }
    }

    &--secondary {
        background-color: var.$color-bg-btn-secondary;
        border: 1px solid var.$color-border-btn-secondary;

        &:hover {
            background-color: var.$color-bg-btn-secondary-hover;
            color: var.$color-text-btn-secondary-hover;
        }
    }
}