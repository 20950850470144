@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.c-nav {
	color: var.$color-text-nav-base;
	font-weight: 700;

	&--white {
		color: var.$color-text-nav-white;
	}
}

.c-nav__slide-bar {
	background: linear-gradient(transparent 95%, var.$color-bg-slide-bar-base 90%);
	background-repeat: no-repeat;
	background-size: 0%;
	background-position: bottom;
	padding-bottom: 0.8rem;
	transition: background-size 0.5s;

	@include mix.responsive(sm) {
		background: none;
	}

	&:hover {
		background-size: 100%;
	}

	&--white {
		background: linear-gradient(transparent 95%, var.$color-bg-slide-bar-white 90%);
		background-repeat: no-repeat;
		background-size: 0%;
		background-position: bottom;
		padding-bottom: 0.8rem;
		transition: background-size 0.5s;

		@include mix.responsive(sm) {
			background: none;
		}
	}
}