@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.c-tag {
    color: var.$color-text-tag;
    font-weight: 700;
}

.c-tag__bg,
.c-tag__bg-sp {
    width: 100%;
    height: 100%;

    &--one {
        fill: var.$color-bg-tag-one;
    }

    &--two {
        fill: var.$color-bg-tag-two;
    }

    &--three {
        fill: var.$color-bg-tag-three;
    }
}

.c-tag__bg {
    max-width: 27.2rem;
    max-height: 7.4rem;
}

.c-tag__bg-sp {
    max-width: 23rem;
    max-height: 9.4rem;
}

.c-tag__sub-title {
    font-size: var.$font-pc-tag;
    white-space: nowrap;

    @include mix.responsive(md) {
        font-size: var.$font-sp-tag;
    }
}