@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-about {
    padding-bottom: 6.4rem;

    @include mix.responsive(md) {
        padding-bottom: 14rem;
    }

    &__title {
        @include mix.responsive(md) {
            font-size: var.$font-sp-about-title;
        }
    }

    &__lead {
        margin-top: 1.3rem;

        @include mix.responsive(md) {
            padding-bottom: 1.1rem;
        }
    }

    &__image-area {
        position: relative;
        margin-top: 18.3rem;
        width: 100%;

        @include mix.responsive(md) {
            margin-top: 14.2rem;
        }

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%,-50%);
            max-width: 21.8rem;
            width: 100%;
            height: 100%;
            background-image: url(../images/lp/illust_handshake.png);
            background-image: image-set(url(../images/lp/illust_handshake.png) 1x, url(../images/lp/illust_handshake@2x.png) 2x);
            background-image: -webkit-image-set(url(../images/lp/illust_handshake.png) 1x, url(../images/lp/illust_handshake@2x.png) 2x);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            @include mix.z-index(lo);

            @include mix.responsive(md) {
                max-width: 15.2rem;
            }
        }
    }

    &__bg img {
        max-width: 46.1rem;
        width: 100%;

        @include mix.responsive(md) {
            max-width: 28rem;
        }
    }

    &__box {
        position: absolute;
        max-width: 17rem;
        max-height: 17rem;
        width: 100%;
        height: 100%;

        @include mix.responsive(md) {
            max-width: 13rem;
            max-height: 13rem;
        }

        &::before {
            content: "";
            position: absolute;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            max-width: 12rem;
            width: 100%;
            height: 100%;
            @include mix.z-index(lo);

            @include mix.responsive(md) {
                max-width: 8rem;
            }
        }

        &--top {
            top: -11rem;
            left: 48%;
            transform: translateX(-50%);

            @include mix.responsive(md) {
                top: -34%;
            }

            &::before {
                bottom: 20%;
                left: 80%;
                background-image: url(../images/lp/illust_movie.png);
                background-image: image-set(url(../images/lp/illust_movie.png) 1x, url(../images/lp/illust_movie@2x.png) 2x);
                background-image: -webkit-image-set(url(../images/lp/illust_movie.png) 1x, url(../images/lp/illust_movie@2x.png) 2x);
            }
        }

        &--right {
            bottom: 10%;
            left: 60%;

            @include mix.responsive(md) {
                bottom: -15%;
                left: 58%;
            }

            &::before {
                top: 40%;
                left: 80%;
                background-image: url(../images/lp/illust_text.png);
                background-image: image-set(url(../images/lp/illust_text.png) 1x, url(../images/lp/illust_text@2x.png) 2x);
                background-image: -webkit-image-set(url(../images/lp/illust_text.png) 1x, url(../images/lp/illust_text@2x.png) 2x);


                @include mix.responsive(md) {
                    top: 60%;
                    left: unset;
                    right: 0;
                }
            }
        }

        &--left {
            bottom: 10%;
            right: 60%;

            @include mix.responsive(md) {
                bottom: -15%;
            }

            &::before {
                top: 40%;
                right: 80%;
                background-image: url(../images/lp/illust_test.png);
                background-image: image-set(url(../images/lp/illust_test.png) 1x, url(../images/lp/illust_test@2x.png) 2x);
                background-image: -webkit-image-set(url(../images/lp/illust_test.png) 1x, url(../images/lp/illust_test@2x.png) 2x);

                @include mix.responsive(md) {
                    top: 60%;
                    right: unset;
                    left: 0;
                }
            }
        }
    }

    &__label {
        max-width: 13rem;
        width: 100%;

        @include mix.responsive(md) {
            max-width: 11rem;
        }

        &--top,
        &--right,
        &--left {
            position: absolute;
        }


        &--top {
            top: -10%;
            left: -10%;

            @include mix.responsive(md) {
                top: -15%;
                left: -40%;
            }
        }

        &--right {
            top: -10%;
            right: -25%;

            @include mix.responsive(md) {
                top: -18%;
                right:  -5%;
            }
        }

        &--left {
            top: -10%;
            left: -20%;

            @include mix.responsive(md) {
                top: -18%;
                left: -5%;
            }
        }
    }

    &__description {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        color: var.$color-text-base-white;
        font-size: var.$font-pc-about-description;
        font-weight: 700;
        line-height: 1.44;

        @include mix.responsive(md) {
            font-size: var.$font-sp-about-description;
        }

        &--top {
            background-color: var.$color-bg-about-movie;
        }

        &--right {
            background-color: var.$color-bg-about-text;
        }

        &--left {
            background-color: var.$color-bg-about-test;
        }

        &--strong {
            margin-bottom: 0.7rem;
            font-size: var.$font-pc-about-description-strong;
            line-height: unset;

            @include mix.responsive(md) {
                font-size: var.$font-sp-about-description-strong;
            }
        }
    }
}
