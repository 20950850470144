@use "../../foundation/mixin" as mix;

.c-section-title {
	font-size: 3.2rem;
	font-weight: 700;
}

.c-section-sub-title {
	@include mix.responsive(tab) {
		font-size: 2.4rem;
	}
}


.c-section-title--top {
	font-size: 1.6rem;
	font-weight: 700;
}
