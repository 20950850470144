@use "../foundation/mixin" as mix;

.l-section {
    padding-top: 4.2rem;
    padding-bottom: 6.4rem;

    @include mix.responsive(sp) {
        padding-top: 3.8rem;
        padding-bottom: 3.8rem;
        }
}