// breakpoint
$breakpoints: (
    'sm': (max-width: 768px),
    'md': (max-width: 979px),
) !default;

// z-index
$z-index: (nav: 1000, menu: 100, btn: 10, lo: 1, bg:-1);

// レイアウト幅
$layout-width-canvas: 1440px;
$layout-width-container: 980px;
$layout-width-smartphone: 768px;

// ドロワー
$drawer-bar-space: 5px;
$drawer-bar-width: 40px;
$drawer-bar-height: 6px;


//color palette
$palette-black: #000;
$palette-dark-grey: #929292;
$palette-grey: #cbcbcb;
$palette-light-grey: #f2f2f2;
$palette-white: #fff;
$palette-blue: #2b5ab1;
$palette-light-blue: #5681d0;
$palette-deep-blue: #022272;
$palette-green: #56b539;
$palette-blue-green: #1eb497;
$palette-orange: #f07c00;
$palette-pink: #eb5485;

//background-color
$color-bg-primary: $palette-blue;
$color-bg-secondary: $palette-white;
$color-bg-tertiary: $palette-light-grey;

//bg-header
$color-bg-header: $palette-white;

//bg-drawer
$color-bg-drawer: $palette-blue;
$color-bg-drawer-bar: $palette-deep-blue;

//bg-slide-bar
$color-bg-slide-bar-base: $palette-black;
$color-bg-slide-bar-white: $palette-white;

//bg-about
$color-bg-about-movie: $palette-pink;
$color-bg-about-text: $palette-orange;
$color-bg-about-test: $palette-blue;

//bg-usecase
$color-bg-usecase: $palette-light-grey;
$color-bg-usecase-card: $palette-blue;

//bg-carousel
$color-bg-carousel-dot-active: $palette-blue;
$color-bg-carousel-dot-inactive: $palette-dark-grey;

//bg-contents
$color-bg-contents-card: $palette-white;

//bg-cta
$color-bg-cta: $palette-blue;

//bg-tag
$color-bg-tag-one: $palette-green;
$color-bg-tag-two: $palette-blue;
$color-bg-tag-three: $palette-orange;
$color-bg-flow-bg: $palette-grey;

//bg-table
$color-bg-table: $palette-blue;

//bg-menu
$color-bg-menu: $palette-blue;

//bg-flow
$color-bg-flow: $palette-light-blue;

//bgr-btn
$color-bg-btn-icon: $palette-white;

//bgr-btn-primary
$color-bg-btn-primary: $palette-blue-green;
$color-bg-btn-primary-hover: $palette-white;
$color-bg-btn-icon-primary-hover: $palette-blue-green;

//bgr-btn-secondary
$color-bg-btn-secondary: $palette-orange;
$color-bg-btn-secondary-hover: $palette-white;
$color-bg-btn-icon-secondary-hover: $palette-orange;


//border
//b-nav
$color-border-nav: $palette-black;
$color-border-nav-white: $palette-white;

//b-contents
$color-border-top-contents: $palette-blue;

//b-function
$color-border-function: $palette-black;

//b-menu
$color-border-menu: $palette-deep-blue;
$color-border-menu-list: $palette-white;

//b-table
$color-border-table: $palette-blue;

//b-btn
$color-border-btn-primary: $palette-blue-green;
$color-border-btn-secondary: $palette-orange;


//text
//logo
$color-text-logo: $palette-deep-blue;
$color-text-logo-white: $palette-white;
$color-text-white: $palette-white;

//nav
$color-text-nav-base: $palette-black;
$color-text-nav-white: $palette-white;
$color-text-drawer: $palette-white;
$color-text-drawer-icon: $palette-deep-blue;

//heading
$color-text-h2: $palette-black;
$color-text-h2-white: $palette-white;
$color-text-h3: $palette-white;

//btn
$color-text-btn: $palette-white;
$color-text-btn-primary-hover: $palette-blue-green;
$color-text-btn-secondary-hover: $palette-orange;

//base
$color-text-base: $palette-black;
$color-text-base-white: $palette-white;

//tag
$color-text-tag: $palette-white;

//contact
$color-text-contact: $palette-white;

//link
$color-text-link: $palette-deep-blue;
$color-text-link-hover: $palette-orange;

//menu
$color-text-menu: $palette-deep-blue;
$color-text-menu-list: $palette-white;

//title
$color-title-white: $palette-white;
$color-title-contents: $palette-white;

//icon
$color-icon-btn: $palette-white;
$color-icon-btn-primary-hover: $palette-blue-green;
$color-icon-btn-secondary-hover: $palette-orange;

//balloon
$color-about-balloon: $palette-deep-blue;



//font-size
$size-xxs: 1rem;
$size-xs: 1.2rem;
$size-s: 1.4rem;
$size-base: 1.6rem;
$size-m: 1.8rem;
$size-l: 2rem;
$size-xl: 2.4rem;
$size-xxl: 2.6rem;
$size-xxxl: 2.8rem;
$size-xxxxl: 3.2rem;
$size-xxxxxl: 3.4rem;
$size-xxxxxxl: 4rem;
$size-ul: 6rem;

//pc
$font-pc-h1: $size-xxxl;
$font-pc-h2: $size-xxxxl;
$font-pc-h3: $size-xl;
$font-pc-lead: $size-m;
$font-pc-text: $size-base;
$font-pc-text-small: $size-s;

//sp
$font-sp-h1: $size-xl;
$font-sp-h2: $size-xxxxl;
$font-sp-h3: $size-xl;
$font-sp-text: $size-s;
$font-sp-text-small: $size-xs;

//logo
$font-logo: $size-s;
$font-logo-top-sp: $size-xxs;
$font-logo-left-sp: $size-xs;
$font-logo-strong: $size-xxl;

$font-logo-mv: $size-xxxl;
$font-logo-mv-top-pc: $size-xxxl;
$font-logo-mv-top-sp: $size-m;
$font-logo-mv-left-sp: $size-l;
$font-logo-mv-strong-pc: $size-ul;
$font-logo-mv-strong-sp: $size-xxxxxxl;

//menu
$font-sp-menu: $size-xxs;
$font-sp-menu-list: $size-s;

//tag
$font-pc-tag: $size-xl;
$font-sp-tag: $size-base;

//btn
$font-pc-btn: $size-base;
$font-sp-btn: $size-s;

//about
$font-sp-about-title: $size-xl;
$font-pc-about-description: $size-m;
$font-sp-about-description: $size-s;
$font-pc-about-description-strong: $size-xxxl;
$font-sp-about-description-strong: $size-xl;

//point
$font-sp-point-title: $size-xl;

//usecase
$font-sp-usecase-description: $size-m;

//contents
$font-contents-description: $size-xs;
$font-contents-label: $size-xxs;

//flow
$font-flow-description: $size-xl;

//guide
$font-sp-guide-lead: $size-base;
$font-sp-guide-description: $size-xs;

//function
$font-function-description: $size-xs;
$font-function-notes: $size-xs;

//price
$font-price-title: $size-xxxl;
$font-price-def-description: $size-l;
$font-price-def-description-strong: $size-xxxxxl;
$font-price-notes: $size-s;
$font-price-description: $size-s;

//privacy
$font-privacy-title: $size-s;
$font-privacy-description-pc: $size-s;
$font-privacy-description-sp: $size-xs;
