@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-mv {
    padding-top: 3.2rem;

    @include mix.responsive(md) {
        padding-top: 2.5rem;
        padding-bottom: 3.6rem;
    }

    &__top {
        padding-bottom: 4.2rem;
        color: var.$color-text-base-white;

        @include mix.responsive(md) {
            padding-bottom: 1.7rem;
        }
    }

    &__list {

        & li:nth-child(1) {
            font-size: 2rem;
        }

        & li:nth-child(2) {
            margin-top: 2.8rem;
        }

        & li:nth-child(3) {
            position: relative;
            margin-top: 3.6rem;
        }

        & li:nth-child(4) {
            gap: 2.4rem;
        }

        @include mix.responsive(md) {
            text-align: center;

            & li:nth-child(1) {
                order: 4;
                margin-top: 2.3rem;
                font-size: 1.8rem;
            }

            & li:nth-child(2) {
                order: 1;
                margin-top: unset;
            }

            & li:nth-child(3) {
                order: 2;
                margin: 1.8rem auto 0;
                max-width: 35.2rem;
            }

            & li:nth-child(4) {
                order: 3;
                justify-content: center;
                gap: 1.4rem;
                margin: 3.4rem auto 0;
            }
        }
    }

	&__logo {
        font-size: var.$font-pc-h2;

        @include mix.responsive(md) {
            justify-content: center;
            align-items: center;
        }

		&--top {
            font-size: var.$font-logo-mv-top-pc;

			@include mix.responsive(md) {
                font-size: var.$font-logo-mv-top-sp;
			}
		}

        &--bottom {
            margin-top: 0.72rem;
        }

		&--left {
			@include mix.responsive(md) {
				font-size: var.$font-logo-mv-left-sp;
			}
		}

        &--strong {
            margin-left: 2rem;
            font-size: var.$font-logo-mv-strong-pc;

            @include mix.responsive(md) {
                margin-left: 0.8rem;
                font-size: var.$font-logo-mv-strong-sp;
			}
        }
	}

    &__thumbnail{
        position: absolute;
        bottom: -7rem;
        left: 51.4rem;
        max-width: 58rem;
        width: 100%;
        height: 329px;

        @include mix.responsive(md) {
            position: unset;
            max-width: 35.2rem;
            height: auto;
        }

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: -3%;
            left: -15%;
            @include mix.z-index(lo);
            max-width: 16.6rem;
            width: 100%;
            height: 100%;
            background-image: url(../images/lp/illust_handshake.png);
            background-image: image-set(url(../images/lp/illust_handshake.png) 1x, url(../images/lp/illust_handshake@2x.png) 2x);
            background-image: -webkit-image-set(url(../images/lp/illust_handshake.png) 1x, url(../images/lp/illust_handshake@2x.png) 2x);
            background-position: bottom center;
            background-size: contain;
            background-repeat: no-repeat;

            @include mix.responsive(md) {
                bottom: -1.5rem;
                right: 0;
                left: unset;
                max-width: 13.5rem;
            }
        }
    }

    &__body {
        padding: 4.2rem 0.8rem 5.6rem;

        @include mix.responsive(md) {
            padding: 3.4rem 0 3.3rem;
        }
    }
}