@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-contact {
    padding: 4.2rem 0;
    color: var.$color-text-contact;
    font-weight: 700;

    @include mix.responsive(md) {
        padding: 2.7rem 0 3.6rem;
	}

    &__btn-area {
        gap: 2.4rem;
        margin-top: 2.9rem;
    }
}