@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-price {
    padding-bottom: 0;
    color: var.$color-text-base-white;

    @include mix.responsive(md) {
        padding-top: 3.8rem;
    }

    &__list {
        margin-top: 4.2rem;
        padding: 4.2rem 5rem 3.5rem;
        max-width: 70rem;
        background-color: var.$color-bg-secondary;
        color: var.$color-text-base;
        font-weight: 700;
        border-radius: 8px;

        @include mix.responsive(md) {
            margin-top: 3.2rem;
            padding: 3.3rem 2.4rem 1.4rem;
        }
    }

    &__item {
        flex-wrap: nowrap;
        max-width: 60rem;
        width: 100%;

        @include mix.responsive(sm) {
            flex-direction: column;
            max-width: 30rem;
        }
    }

    &__def-title {
        padding: 0 4.8rem;
        background: var.$color-bg-table;
        color: var.$color-title-white;
        font-size: var.$font-price-title;
        line-height: unset;
        white-space: nowrap;

        @include mix.responsive(sm) {
            padding: 3.3rem 0;
            width: 100%;
        }
    }

    &__block {
        padding: 1.7rem 0 1.4rem;
        width: 60%;
        border: 6px solid var.$color-border-table;

        @include mix.responsive(md) {
            padding: 1.9rem 0 2.4rem;
            width: 100%;
        }
    }

    &__def-description {
        font-size: var.$font-price-def-description;
        line-height: unset;

        &--strong {
            font-size: var.$font-price-def-description-strong;
        }
    }

    &__notes {
        margin-top: 0.6rem;
        font-size: var.$font-price-notes;
        line-height: unset;
    }

    &__description {
        margin-top: 0.3rem;
        font-size: var.$font-price-description;

        @include mix.responsive(md) {
            margin-top: 0.7rem;
        }
    }
}