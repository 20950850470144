@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;


.c-hamburger {
	@include mix.z-index(nav);
}

.c-hamburger__icon {
	display: inline-block;
	position: relative;
	text-align: center;
	cursor: pointer;
	@include mix.z-index(nav);

	&::after {
		content: "MENU";
		display: block;
		color: var.$color-text-drawer-icon;
		font-size: 0.8rem;
		text-align: center;
		font-weight: 700;
		line-height: 1;
		margin-top: 8px;
	}
}

.c-hamburger__bars {
	display: inline-block;
	width: var.$drawer-bar-width;
	height: var.$drawer-bar-height + (var.$drawer-bar-space + var.$drawer-bar-height) * 2;
	position: relative;
	vertical-align: bottom;
}

.c-hamburger__bar {
	position: absolute;
	right: 0;
	left: 0;
	display: block;
	background-color: var.$color-bg-drawer-bar;
	width: 100%;
	height: var.$drawer-bar-height;
	border-radius: 2px;
	transition: all 0.3s linear 0s;

	&:nth-of-type(1) {
		top: 0;
	}

	&:nth-of-type(2) {
		top: var.$drawer-bar-space + var.$drawer-bar-height;
	}

	&:nth-of-type(3) {
		top: (var.$drawer-bar-space + var.$drawer-bar-height) * 2;
	}

	.active & {

		&:nth-of-type(1) {
			top: 10px;
			transform: rotate(-45deg);
		}

		&:nth-of-type(2) {
			background-color: transparent;
		}

		&:nth-of-type(3) {
			top: 10px;
			transform: rotate(45deg);
		}
	}
}
