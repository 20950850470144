@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.c-drawer {
	@include mix.z-index(menu);

	&__content {
		overflow-y: auto;
		position: fixed;
		top  : 0;
		left : 0;
		padding-top: 90px;
		width: 100%;
		color: var.$color-text-drawer;
		background-color: var.$color-bg-drawer;
		text-align: center;
		transform: translateY(-100%);
		transition: all 0.5s;
		@include mix.z-index(menu);

		&--active {
			transform: translateX(0);
			height: 100vh;
		}
	}

	&__item {
		padding-top: 2.4rem;
		font-weight: 700;

		&:nth-child(1) {
			padding-top: 0;
			gap: 1.2rem;
		}
	}
}


