@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-flow {
    padding-top: 3.4rem;
    padding-bottom: 12.8rem;

    @include mix.responsive(md) {
        padding-top: 3.1rem;
        padding-bottom: 14rem;
    }

    &__title {
        margin-top: 1.6rem;
    }

    &__lead {
        margin-top: 1.4rem;

        @include mix.responsive(md) {
            margin-top: 2.9rem;
        }
    }

    &__content {
        position: relative;
        margin-top: 3.3rem;

        @include mix.responsive(md) {
            margin-top: 2.2rem;
        }
    }

    &__top {
        @include mix.responsive(md) {
            flex-wrap: nowrap;
            align-items: flex-start ;
        }
    }

    &__list {
        gap: 0 3.8rem;
        flex-wrap: nowrap;

        @include mix.responsive(md) {
            gap: 1.6rem 0;
            flex-direction: column;
        }
    }

    &__item {
        gap: 0 2.5rem;
    }

    &__tag {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__sub-title {
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);

        &--one,
        &--two,
        &--three {
            left: 45%;

            @include mix.responsive(md) {
                top: 35%;
                left: 50%;
            }
        }
    }

    &__border {
        position: relative;
        width: 100%;
        margin-right: 50px;

        @include mix.responsive(md) {
            justify-content: unset;
            width: unset;
            margin-right: unset;
        }
    }

    &__bottom {
        position: absolute;
        top: 95%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;

        @include mix.responsive(md) {
            position: static;
            transform: none;
            margin-top: 9rem;
        }
    }

    &__box {
        position: relative;
        padding: 1rem;
        max-width: 47rem;
        width: 100%;
        background-color: var.$color-bg-flow;
        border-radius: 10px;

        @include mix.responsive(md) {
            max-width: 34.5rem;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            @include mix.z-index(lo)
        }

        &::before {
            bottom: -7%;
            left: -43px;
            width: 130px;
            height: 174px;
            background-image: url(../images/lp/illust_handshake.png);
            background-image: image-set(url(../images/lp/illust_handshake.png) 1x, url(../images/lp/illust_handshake@2x.png) 2x);
            background-image: -webkit-image-set(url(../images/lp/illust_handshake.png) 1x, url(../images/lp/illust_handshake@2x.png) 2x);

            @include mix.responsive(md) {
                bottom: -100px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &::after {
            top: 40%;
            right: -50px;
            width: 140px;
            height: 100px;
            background-image: url(../images/lp/illust_learn.png);
            background-image: image-set(url(../images/lp/illust_learn.png) 1x, url(../images/lp/illust_learn@2x.png) 2x);
            background-image: -webkit-image-set(url(../images/lp/illust_learn.png) 1x, url(../images/lp/illust_learn@2x.png) 2x);

            @include mix.responsive(md) {
                top: -60px;
                right: 50%;
                transform: translateX(50%);
            }
        }
    }

    &__description {
        padding: 3.2rem 0;
        background-color: var.$color-bg-secondary;
        font-weight: 700;
        font-size: var.$font-flow-description;
        line-height: 1.3;

        @include mix.responsive(md) {
            padding: 5.3rem 0 7.1rem;
        }
    }
}
