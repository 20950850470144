@use "../foundation/mixin" as mix;

.l-row {
	display: flex;
	flex-wrap: wrap;
}

.l-row--middle {
	align-items: center;
}

.l-row--center {
	justify-content: center;
}

.l-row--between {
	justify-content: space-between;
}

.l-row__col {
	display: flex;
	flex-direction: column;
}

