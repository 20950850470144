@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-usecase {
    padding-top: 3.4rem;
    padding-right: 1.4rem;
    padding-left: 1.4rem;

    @include mix.responsive(md) {
        padding-top: 2.9rem;
        padding-bottom: 3.6rem;
    }

    &__title {
        margin-top: 1.6rem;
    }

    &__lead {
        margin-top: 2.1rem;
    }

    &__list {
        gap: 2.4rem;
        margin-top: 4.7rem;

        @include mix.responsive(sm) {
            margin: 2.9rem auto 0;
            max-width: 30rem;
            width: 100%;
            height: auto;
        }
    }

    &__item {
        width: 30%;

        @include mix.responsive(sm) {
            width: 100%;
        }
    }

    &__thumbnail {
        & img {
            aspect-ratio: 2.414 / 1;
            object-fit: cover;
        }
	}

    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var.$color-bg-usecase-card;
        color: var.$color-text-base-white;
        aspect-ratio: 2.414 / 1;
    }

    &__description {
        @include mix.responsive(sp) {
            font-size: var.$font-sp-usecase-description;
        }
    }
}