@use "../../foundation/variable" as var;
@use "../../foundation/mixin" as mix;

.p-function {
    @include mix.responsive(md) {
        padding: 3.8rem 1.5rem;
    }

    &__list {
        gap: 1.05rem;
        margin-top: 5.2rem;
        max-width: 60rem;

        @include mix.responsive(md) {
            margin-top: 4rem;
        }
    }

    &__item {
        padding-bottom: 0.8rem;
        border-bottom: 0.5px solid var.$color-border-function;

        @include mix.responsive(md) {
            padding-bottom: 1rem;
        }
    }

    &__block {
        margin-top: 0.8rem;
    }

    &__def-description {
        font-size: var.$font-function-description;
    }

    &__notes {
        font-size: var.$font-function-notes;

        & span {
            text-decoration: underline;
        }
    }
}